export enum Experiment {
  EnableSectionsTabInMobileAS = 'specs.membersAboutOOI.enableSectionsMobileAS',
  EnableOOIInEditor = 'specs.membersArea.framelessEditorProfilePage',
  EnableCSSOptimization = 'specs.membersAboutOOI.EnableCSSOptimization',
  AboutPublishValidation = 'specs.about.MembersAboutSpecs$ValidateLinksInAboutContent',
  RateFromAppSettings = 'specs.membersArea.rateFromAppSettings',
  UseMembersAboutV2 = 'specs.membersArea.useMembersAboutV2',
  BlogLiveSiteEditingDeprecation = 'specs.wixBlog.LiveSiteEditorDeprication',
  EnableDesignTabResetButtonPerPage = 'specs.membersAboutOOI.EnableDesignTabResetButtonPerPage',
}
